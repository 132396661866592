import "./contacts.scss";
import contacts_bg from "../../img/contacts_bg.png";

const Contacts = () => {
  return (
    <section id="contacts" className="contacts">
      <img
        className="cbg"
        src={contacts_bg}
        alt="Фоновое изображение контактов"
      />
      <div className="container">
        <div className="contacts__content">
          <div className="contacts__content-left">
            <div className="square">
              <h3>Контакты</h3>
            </div>
          </div>

          <div className="contacts__content-right">
            <div className="contacts__content-right-item">
              <p>Электронная почта</p>
              <h3>info@penthaus.city</h3>
            </div>
            <div className="contacts__content-right-item">
              <p>Телефоны</p>
              <h3>8-800-600-53-82</h3>
              <h3>8-495-968-87-90</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
