import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";

import "./feedback.scss";

function Feedback() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    console.log(data);

    await fetch("../phpmailer/feedback.php", {
      body: JSON.stringify(data),
      method: "POST",
    })
      .then(() => {
        setIsLoading(false);
        setIsSuccess(true);
      })
      .then(() => {
        setTimeout(setIsSuccess, 5000, false);
      });
    reset();
  };

  return (
    <section id="feedback" className="feedback">
      <div className="container">
        <div className="feedback__content">
          <div className="feedback__content-title">
            <h2>Оставить заявку</h2>
            <p>
              Заполните форму для получения бесплатной консультации или
              свяжитесь с нами любым удобным для вас способом
            </p>
            {isSuccess && (
              <div className="alert">Сообщение успешно отправлено!</div>
            )}
          </div>
          <div className="feedback__content-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="feedback__content-form-item">
                <input
                  placeholder="Ваше имя"
                  type="text"
                  {...register("name", {
                    required: true,
                    validate: (value) => value !== "",
                  })}
                />
                {errors.name && <p>Введите имя</p>}
              </div>
              <div className="feedback__content-form-item">
                <Controller
                  control={control}
                  name="phone"
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <PhoneInput
                        inputClass="shadow-none"
                        placeholder="+7 (999) 999-99-99"
                        onChange={onChange}
                        value={value}
                        country={"ru"}
                        disableDropdown={true}
                      />
                    </>
                  )}
                />
                {errors["phone"] && <p>Введите номер телефона</p>}
              </div>

              <div className="feedback__content-form-item">
                <button className="btns btns-primary" type="submit">
                  {isLoading ? (
                    <Spinner size="sm" animation="border" />
                  ) : (
                    "Закзать звонок"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Feedback;
