import { SlickSlider } from "../../components";

import "./works.scss";
const Works = () => {
  return (
    <section id="works" className="works">
      <div className="container">
        <div className="works__content">
          <div className="works__content-title">
            <h2>Наши работы</h2>
          </div>
          <div className="works__content-slider">
            <SlickSlider />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Works;
