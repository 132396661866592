import { useContext, useRef } from "react";
import { MenuContext } from "../../context/navState";
import NavState from "../../context/navState";
import useOnClickOutside from "../../hooks/useOnClickOutside.js";
import { HamburgerButton, SlideMenu } from "../../components";
import logo from "../../img/logo.svg";
import "./header.scss";

const Header = () => {
  const node = useRef();
  const { toggleMenuMode, isMenuOpen } = useContext(MenuContext);
  useOnClickOutside(node, () => {
    if (isMenuOpen) {
      toggleMenuMode();
    }
  });
  return (
    <NavState>
      <header className="header">
        <div className="container">
          <div className="header__content">
            <div className="header__content-logo">
              <a href="/">
                <img src={logo} alt="Пентхаус" />
              </a>
            </div>
            <div className="header__content-nav">
              <nav>
                <ul>
                  <li>
                    <a href="#main">Главная</a>
                  </li>
                  <li>
                    <a href="#service">Услуги</a>
                  </li>
                  <li>
                    <a href="#advantages">Преимущества</a>
                  </li>
                  <li>
                    <a href="#works">Наши работы</a>
                  </li>
                  <li>
                    <a href="#contacts">Контакты</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="header__content-btn">
              <a href="#feedback">
                <button className="btns">Заказать звонок</button>
              </a>
            </div>
            <HamburgerButton />
            <SlideMenu />
          </div>
        </div>
      </header>
    </NavState>
  );
};

export default Header;
