import Slider from "react-slick";
import "./slider.scss";

import one from "../../img/slider/1.png";
import two from "../../img/slider/2.png";
import three from "../../img/slider/3.png";
import next from "../../img/slider/next.svg";
import prev from "../../img/slider/prev.svg";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{ ...style, top: "-120px", right: "30px" }}
    >
      <img src={next} alt="Следующий слайд" />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{ ...style, top: "-120px", left: "auto", right: "90px" }}
    >
      <img src={prev} alt="Предыдущий слайд" />
    </div>
  );
}

const SlickSlider = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1385,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 976,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          nextArrow: false,
          prevArrow: false,
        },
      },
    ],
  };
  return (
    <div className="slider">
      <Slider {...settings}>
        <div className="slide">
          <img src={one} alt="one" />
        </div>
        <div className="slide">
          <img src={two} alt="two" />
        </div>
        <div className="slide">
          <img src={three} alt="three" />
        </div>
        <div className="slide">
          <img src={one} alt="one" />
        </div>
      </Slider>
    </div>
  );
};

export default SlickSlider;
