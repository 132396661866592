import { useContext } from "react";
import { MenuContext } from "../../context/navState";
import HamburgerButton from "../HamburgerButton";

import "./slide.scss";

const SlideMenu = () => {
  const { isMenuOpen, toggleMenuMode } = useContext(MenuContext);
  document.body.style = `overflow: ${isMenuOpen && "hidden"}`;
  return (
    <nav
      style={{
        transform: isMenuOpen ? "translateX(0)" : "translateX(100%)",
        width: "100%",
      }}
      className="mobile-menu"
    >
      <HamburgerButton />
      <div onClick={toggleMenuMode} className="mobile-menu-links">
        <ul>
          <li>
            <a href="#main">Главная</a>
          </li>
          <li>
            <a href="#service">Услуги</a>
          </li>
          <li>
            <a href="#advantages">Преимущества</a>
          </li>
          <li>
            <a href="#works">Наши работы</a>
          </li>
          <li>
            <a href="#contacts">Контакты</a>
          </li>
        </ul>
        <div className="mobile-menu-btn">
          <a href="#feedback">
            {" "}
            <button className="btns">Заказать звонок</button>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default SlideMenu;
